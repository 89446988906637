import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Info from '../components/Info';
import AirBnb from '../assets/svgs/airbnb';
import Anthropologie from '../assets/svgs/anthro';
import barHarbor from '../assets/images/CeremonyHeader.jpg';
import sweetPeas from '../assets/images/sweetpeas.jpeg';
import covidForm from '../assets/docs/CertificateofCompliance2021.pdf';

import pa1 from '../assets/images/IMG_0130.jpeg';
import pa2 from '../assets/images/IMG_1624.jpeg';
import pa3 from '../assets/images/IMG_1773.jpeg';

const registries = [
  {
    "title" : "Crate & Barrel",
    "image" : "https://cdn.worldvectorlogo.com/logos/crate-barrel.svg",
    "registryUrl" : "https://www.crateandbarrel.com/gift-registry/patrick-emery-and-amanda-norton/r6024320",
  },
  {
    "title" : "CB2",
    "image" : "https://i.pinimg.com/474x/91/de/9b/91de9bfb2d5ca2ceb7944991b98f0906--logo-and-identity-branding.jpg",
    "registryUrl": "https://www.cb2.com/gift-registry/patrick-emery-and-amanda-norton/r6048741",
  },
  {
    "title" : "REI",
    "image" : "https://cdn.worldvectorlogo.com/logos/rei.svg",
    "registryUrl": "https://www.myregistry.com/rei/wedding-registry/patrick-emery-and-amanda-norton-boston-ma/2313499",
  }
]

const accomodations = [
  {
    "title" : "Air BnB",
    "image" : "http://static.dezeen.com/uploads/2014/07/Airbnb-rebrand-by-DesignStudio_dezeen_468_8.jpg",
    "url" : "https://www.airbnb.com/s/Bar-Harbor--ME--United-States/homes",
  },
  {
    "title" : "VRBO",
    "image" : "https://www.vrmintel.com/wp-content/uploads/2019/03/VRBO-Logo-Change.jpg",
    "url": "https://www.vrbo.com/search/keywords:bar-harbor-me-usa",
  },
  {
    "title" : "Bar Harbor Inn",
    "image" : "https://media-cdn.tripadvisor.com/media/photo-m/1280/18/c7/e9/6a/bar-harbor-inn.jpg",
    "url": "https://barharborinn.com/hotel/",
  },
  {
    "title" : "Kayak",
    "image" : "https://media.glassdoor.com/sqll/37851/kayak-com-squarelogo-1501243619742.png",
    "url": "https://www.kayak.com/hotels/Bar-Harbor,ME-c21392/2020-06-19/2020-06-21/2adults?sort=rank_a",
  },
]

const goToUrl = (url) => {
  if(typeof window !== 'undefined') {
    window.location = url;
  }
}

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <div style={{ paddingTop: '12rem' }} />
          <Scroll type="id" element="info">
            <a href="#info" className="btn btn-primary" style={{ width: '350px' }}>
              Click For Details
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="info" className="about-section text-center" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}>
      <Info />
    </section>
    
    <section id="about" className="location-section bg-light">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}>
        <h2 style={{ fontWeight: 800 }}>About Us</h2>
        <div className="row align-items-start justify-content-center no-gutters mb-4 mb-lg-5">
          <div className="text-center col-xl-2 col-lg-2">
            <div className="row align-items-start no-gutters mb-4 mb-lg-5">
              <div className="text-center" style={{ borderRadius: '8px', height: '150px', width: '180px', backgroundImage: `url(${pa3})`, backgroundSize: '180px 180px' }} ></div>
              <div className="text-center" style={{ borderRadius: '8px', height: '150px', width: '180px', backgroundImage: `url(${pa1})`, backgroundSize: '180px 150px' }} ></div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            <p style={{ paddingLeft: 12, paddingRight: 12 }}>
              Hi, we're Pat &amp; Amanda. 
              It was 2012 and Patrick was working at a record store in Daytona Beach. 
              One day Amanda walked in looking for a Film School CD, and they immediately bonded over their love of music and Tia Cori's Tacos. 
              Amanda and Pat have been dating for about nine years, and have two precious border collies, Genevieve &amp; Clementine. They live in 
              Boston. 
              <br /><br />
              Oh and we also have hobbies, which include: cooking, board game playing, and hiking.
            </p>
          </div>
          <div className="text-center col-xl-3 col-lg-3">
            <div style={{ borderRadius: '8px', height: '300px', width: '200px', backgroundImage: `url(${pa2})`, backgroundSize: '200px 300px' }} ></div>
          </div>
        </div>
        <br />
      </div>
    </section>

    <section id="covid" className="location-section bg-dark">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}>
        <h2 style={{ color: '#FFFFFF', fontWeight: 800 }}>COVID Guidelines</h2>
          <p className="text-white">
            To help Keep Maine Healthy, guests of Maine commercial lodging establishments, campgrounds, 
            seasonal rentals, or short-term rentals are required to complete this certificate prior to their stay. 
            Residents of Maine, and <a href="https://www.maine.gov/covid19/">residents of states currently exempted from testing or quarantine requirements</a> do not need to fill out the Certificate of Compliance.
            The Maine State CDC recommends that all children older than 12 months receive a COVID-19 test.
          </p>
          <p className="text-white">Please print and fill out this form prior to arriving to Maine as we will request proof upon arrival to either location.</p>
          <center>
            <a href={covidForm} download="CertificateofCompliance2021">
              <button className="btn btn-secondary" style={{ width: '300px' }}>Download Form</button>
            </a>
          </center>
          <br />
      </div>
    </section>

    <section id="location" className="location-section bg-light">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}>
        <h2 style={{ fontWeight: 800 }}>Locations</h2>
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="text-center col-xl-6 col-lg-7">
            <img height="350" width="500" src={sweetPeas} alt="" />
          </div>
          <div className="col-xl-6 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4 className="text-black">Rehersal: Sweet Peas Cafe</h4>
              <p className="text-black">
                Sweet Pea's Cafe sits between Bar Harbor Cellars Winery and Sweet Pea's Farm on Mount Desert Island. We offer fresh, local ingredients in a beautiful Maine setting.
              </p>
              <p />
              <label className="text-black"><b>Address:</b> 854 State Highway 3, Bar Harbor, ME 04609-7238</label>
              <label className="text-black"><b>Phone:</b> 1-(207)-801-9099</label>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="featured-text text-center text-lg-left">
              <h4 className="text-black">Ceremony: Bar Harbor Inn</h4>
              <p className="text-black">
                Located harborside in the heart of Bar Harbor overlooking Frenchman Bay and the Porcupine Islands, the Bar Harbor Inn is an iconic and historic New England vacation destination welcoming guests worldwide with genuine Maine hospitality, superior service, and timeless charm.
              </p>
              <label className="text-black"><b>Address:</b> 1 Newport Drive, Bar Harbor, Maine</label>
              <label className="text-black"><b>Phone:</b> 1-(844)-814-1668</label>
            </div>
          </div>
          <div className="text-center col-xl-6 col-lg-6">
            <img height="310" width="500" src={barHarbor} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section id="registry" className="registry-section bg-dark">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px', paddingBottom: '70px' }}>
        <h2 style={{ fontWeight: 800, color: '#FFFFFF' }}>Registry</h2>
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <button className="col-lg-6" style={{ border: 'none', height: 200, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(registries[0].registryUrl)}>
            <img height="200" width="200" src={registries[0].image} alt="" />
          </button>
          <button className="col-lg-6" style={{ border: 'none', height: 200, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(registries[1].registryUrl)}>
            <img height="202" width="310" src={registries[1].image} alt="" />
          </button>
          <button className="col-lg-6" style={{ border: 'none', height: 200, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(registries[2].registryUrl)}>
            <img height="200" width="300" src={registries[2].image} alt="" />
          </button>
          <button className="col-lg-6" style={{ border: 'none', height: 200, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl('https://www.anthropologie.com/registry/listing?registryId=07EAD290EB43')}>
            <Anthropologie height={200} width={300}/>
          </button>
        </div>
      </div>
    </section>

    <section id="accomodations" className="accomodations-section bg-light">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px' }}>
        <h2 style={{ fontWeight: 800 }}>Accomodations</h2>
        <div className="row justify-content-left no-gutters mb-5 mb-lg-0">
          <button className="col-lg-3" style={{ border: 'none', height: 180, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(accomodations[0].url)}>
            <AirBnb /><br />
            <p className="text-black">Airbnb</p>
          </button>
          <button className="col-lg-3" style={{ border: 'none', height: 180, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(accomodations[1].url)}>
            <img height="150" width="200" src={accomodations[1].image} alt="" /><br />
            <p className="text-black">Vrbo</p>
          </button>
          <button className="col-lg-3" style={{ border: 'none', height: 180, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(accomodations[2].url)}>
            <img height="150" width="200" src={accomodations[2].image} alt="" /><br />
            <p className="text-black">Bar Harbor Inn</p>
          </button>
          <button className="col-lg-3" style={{ border: 'none', height: 180, background: '#FFF', textAlign: 'center' }} onClick={() => goToUrl(accomodations[3].url)}>
            <img height="150" width="200" src={accomodations[3].image} alt="" /><br />
            <p className="text-black">Kayak</p>
          </button>
        </div>
        <br />
        <h4 className="text-black">Bar Harbor Inn Room Block Info:</h4>
        <p className="text-black">If you are looking to stay at the Bar Harbor Inn, we have several room types blocked for each night. Please call 1-800-248-3351 and use reference number <b>10H1H9</b></p>
        <p /><p />
      </div>  
    </section>


    <section id="photos" className="photos-section bg-dark">
      <div className="container" style={{ display: 'flex', flexDirection: 'column', paddingTop: '70px', height: '400px' }}>
        <h2 className="text-white" style={{ fontWeight: 800 }}>Photos</h2>
        <p className="text-white">Coming Soon!</p>
      </div>  
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
