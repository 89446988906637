import React from 'react';
import PropTypes from 'prop-types';

const Party = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 512 550" width={width}>
<path style={{ fill: "#E5F9F6" }} d="M351.723,499.864L289.416,478.2c-12.019-4.179-20.075-15.509-20.075-28.233V253.741h-26.682v196.225
	c0,12.725-8.056,24.055-20.075,28.233l-62.307,21.663c-2.423,1.021-1.693,4.632,0.936,4.632h189.573
	C353.416,504.496,354.146,500.885,351.723,499.864z"/>
<path style={{ fill: "#D6E5E2" }} d="M351.723,499.864L289.416,478.2c-12.019-4.179-54.813-4.179-66.832,0l-62.307,21.663
	c-2.423,1.021-1.693,4.632,0.936,4.632h189.573C353.416,504.496,354.146,500.885,351.723,499.864z"/>
<path style={{ fill: "#E5F9F6" }} d="M234.097,258.941L48.139,54.362c-4.471-4.919-0.981-12.807,5.666-12.807h404.391
	c6.647,0,10.137,7.888,5.666,12.807L277.903,258.941C266.159,271.861,245.841,271.861,234.097,258.941z"/>
<path style={{ fill: "#D6E5E2" }} d="M458.196,41.555h-47.563L223.565,247.354l10.532,11.587c11.744,12.92,32.062,12.92,43.806,0
	L463.862,54.362C468.333,49.443,464.843,41.555,458.196,41.555z"/>
<path style={{ fill: "#CDEAE0" }} d="M96.557,107.628l137.541,151.313c11.744,12.919,32.06,12.919,43.804,0.001l137.542-151.314
	L96.557,107.628L96.557,107.628z"/>
<path style={{ fill: "#BFD8CE" }} d="M247.816,267.474c0.245,0.07,0.488,0.147,0.735,0.21c0.693,0.179,1.393,0.324,2.095,0.452
	c0.781,0.143,1.564,0.263,2.352,0.343c0.83,0.084,1.663,0.125,2.497,0.139c0.168,0.003,0.336,0.013,0.504,0.013
	c0.797,0,1.593-0.041,2.387-0.105c0.205-0.016,0.41-0.026,0.615-0.047c6.984-0.707,13.762-3.885,18.9-9.537l137.542-151.314h-64.87
	L223.565,247.354l10.532,11.587c0.32,0.352,0.65,0.691,0.982,1.024c0.049,0.049,0.095,0.101,0.143,0.149
	c3.449,3.406,7.534,5.791,11.872,7.154C247.334,267.343,247.576,267.406,247.816,267.474z"/>
<ellipse transform="matrix(-0.7257 -0.688 0.688 -0.7257 342.9868 468.2197)" style={{ fill: "#60A83F" }} cx="264.828" cy="165.739" rx="30.767" ry="27.349"/>
<g>
	
		<ellipse transform="matrix(-0.7257 -0.688 0.688 -0.7257 435.2231 425.6092)" style={{ fill: "#7DC959" }} cx="302.453" cy="126.047" rx="30.767" ry="27.349"/>
	
		<ellipse transform="matrix(-0.7257 -0.688 0.688 -0.7257 527.4892 382.9897)" style={{ fill: "#7DC959" }} cx="340.09" cy="86.345" rx="30.767" ry="27.349"/>
</g>
<path style={{ fill: "#60A83F" }} d="M283.646,145.898c12.331,11.691,30.752,12.283,41.144,1.322c9.981-10.527,8.92-27.878-2.103-39.592
	h-44.79C270.069,118.719,272.293,135.134,283.646,145.898z"/>
<path d="M283.456,263.989l185.959-204.58c4.095-4.506,5.109-10.764,2.645-16.332c-2.463-5.568-7.776-9.027-13.864-9.027H400.03
	l20.274-21.383c2.852-3.008,2.725-7.757-0.282-10.609c-3.007-2.85-7.756-2.725-10.609,0.283l-30.064,31.71H53.805
	c-6.089,0-11.402,3.459-13.865,9.027c-2.463,5.569-1.449,11.827,2.646,16.332l185.959,204.579c1.982,2.18,4.209,4.088,6.61,5.717
	v180.261c0,9.521-6.042,18.019-15.035,21.145l-62.307,21.664c-0.152,0.053-0.302,0.11-0.449,0.172
	c-4.401,1.855-6.814,6.42-5.869,11.101c0.946,4.681,4.942,7.951,9.718,7.951h189.573c4.776,0,8.773-3.269,9.719-7.951
	c0.946-4.681-1.468-9.247-5.869-11.101c-0.147-0.062-0.298-0.12-0.449-0.172l-62.307-21.664
	c-8.992-3.127-15.035-11.624-15.035-21.145v-180.26C279.247,268.077,281.474,266.169,283.456,263.989z M385.801,49.059h72.395
	c0.048,0,0.076,0.001,0.084,0.002c0.042,0.042,0.088,0.146,0.091,0.172c0,0-0.014,0.028-0.062,0.081l-46.185,50.81H375.21
	c3.374-10.809,1.225-23.33-6.154-33.403L385.801,49.059z M286.951,485.288l33.66,11.703H191.389l33.66-11.703
	c15.022-5.223,25.115-19.417,25.115-35.321V275.67c0.331,0.053,0.665,0.09,0.998,0.134c0.176,0.023,0.352,0.049,0.528,0.07
	c0.398,0.047,0.797,0.084,1.196,0.117c0.12,0.01,0.24,0.02,0.36,0.029c0.914,0.068,1.832,0.115,2.755,0.115
	c0.924,0,1.843-0.047,2.759-0.116c0.116-0.009,0.233-0.018,0.349-0.028c0.403-0.034,0.805-0.071,1.206-0.118
	c0.168-0.019,0.335-0.045,0.503-0.067c0.34-0.045,0.681-0.083,1.019-0.137v174.296C261.836,465.871,271.929,480.065,286.951,485.288
	z M272.35,253.893c-1.814,1.996-3.917,3.605-6.222,4.797c-0.056,0.026-0.114,0.044-0.169,0.072c-6.236,3.147-13.683,3.148-19.919,0
	c-0.054-0.027-0.112-0.045-0.167-0.071c-2.305-1.192-4.408-2.801-6.223-4.797L113.519,115.132h27.827
	c4.144,0,7.504-3.36,7.504-7.504s-3.36-7.504-7.504-7.504h-41.47l-46.185-50.81c-0.033-0.036-0.05-0.06-0.055-0.064
	c0.002-0.059,0.049-0.162,0.065-0.182c0,0,0.03-0.009,0.103-0.009h311.315l-6.949,7.329c-6.13-4.021-13.139-6.301-20.316-6.532
	c-9.934-0.318-19.011,3.292-25.531,10.171c-6.521,6.878-9.646,16.125-8.798,26.037c0.111,1.292,0.293,2.574,0.535,3.843
	c-10.826-1.46-21.755,1.782-29.37,9.813c-0.125,0.131-0.237,0.27-0.359,0.404h-98.067c-4.144,0-7.504,3.36-7.504,7.504
	s3.36,7.504,7.504,7.504h90.338c-1.018,4.677-1.066,9.593-0.147,14.439c-1.281-0.173-2.571-0.287-3.867-0.329
	c-9.942-0.322-19.01,3.292-25.531,10.171c-11.614,12.25-11.71,31.605-1.185,45.972c-0.001-0.001-0.001-0.002-0.002-0.003
	l-8.296,8.75c-2.851,3.008-2.725,7.757,0.283,10.609c1.451,1.376,3.308,2.058,5.162,2.058c1.987,0,3.972-0.785,5.447-2.341
	l8.29-8.744l0,0c6.13,4.021,13.139,6.302,20.316,6.532c0.406,0.013,0.809,0.02,1.212,0.02c9.466,0,18.065-3.593,24.32-10.19
	c6.521-6.878,9.646-16.125,8.798-26.037c-0.111-1.291-0.293-2.572-0.534-3.841c1.675,0.226,3.353,0.346,5.02,0.346
	c9.109,0,17.914-3.373,24.35-10.162c7.615-8.032,10.27-19.118,8.235-29.851c1.281,0.173,2.571,0.287,3.866,0.329
	c0.406,0.013,0.809,0.02,1.212,0.02c8.222,0,15.787-2.714,21.732-7.749h33.2L272.35,253.893z M285.58,110.047
	c3.504-3.695,8.344-5.515,13.456-5.515c5.872,0,12.104,2.401,17.08,7.119c9.308,8.826,10.756,22.466,3.228,30.406
	c-7.529,7.938-21.223,7.22-30.532-1.601c-0.001-0.001-0.003-0.003-0.004-0.004l0,0C279.501,131.627,278.053,117.987,285.58,110.047z
	 M281.711,181.751c-3.531,3.724-8.541,5.666-14.158,5.496c-5.932-0.19-11.748-2.712-16.377-7.101
	c-9.309-8.826-10.757-22.466-3.228-30.406c3.382-3.567,8.136-5.508,13.45-5.508c0.236,0,0.472,0.004,0.708,0.011
	c5.93,0.19,11.745,2.711,16.373,7.097c0.001,0.001,0.003,0.003,0.004,0.004c4.629,4.388,7.456,10.062,7.963,15.975
	C286.923,172.901,285.242,178.026,281.711,181.751z M318.48,84.784c-0.478-5.581,1.203-10.706,4.734-14.431
	c3.382-3.566,8.135-5.508,13.45-5.508c0.234,0,0.471,0.004,0.708,0.011c5.932,0.191,11.748,2.712,16.376,7.101
	c9.308,8.825,10.756,22.466,3.228,30.406c-3.531,3.724-8.571,5.666-14.158,5.496c-5.932-0.191-11.748-2.712-16.377-7.101
	C321.814,96.371,318.986,90.698,318.48,84.784z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
);

Party.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Party;
