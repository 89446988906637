/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const Ceremony = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 512 550" width={width}>
<path style={{ fill: "#A86D38" }} d="M256.488,165.673c-3.696-7.393-14.247-7.393-17.943,0l-35.382,70.765
	c-4.307,8.613-16.599,8.613-20.905,0c-3.697-7.393-14.248-7.393-17.943,0L99.259,364.52l54.983,109.965h48.793l104.437-208.875
	L256.488,165.673z"/>
<g>
	<path style={{ fill: "#995F30" }} d="M203.933,236.438c4.306,8.613,16.598,8.613,20.905,0l33.535-67.071l-1.884-3.694
		c-3.696-7.393-14.247-7.393-17.943,0l-35.03,70.061C203.656,235.968,203.808,236.188,203.933,236.438z"/>
	<path style={{ fill: "#995F30" }} d="M122.182,362.061l62.264-122.585c-0.848-0.856-1.597-1.859-2.187-3.038
		c-3.697-7.393-14.248-7.393-17.943,0L99.259,364.519l54.983,109.965h21.674l-53.75-107.499
		C121.39,365.435,121.396,363.607,122.182,362.061z"/>
</g>
<path style={{ fill: "#B78046" }} d="M294.979,461.601c0.126-8.939,5.908-13.246,13.213-12.401c1.791,0.207,3.405-0.968,3.744-2.739
	c3.83-20.036,30.689-24.539,40.827-6.738c2.677,4.7,6.274-1.04,15.295,1.876c1.186,0.383,2.464,0.203,3.485-0.512
	c4.447-3.11,9.828-4.133,14.846-3.135c1.651,0.328,3.277-0.729,3.5-2.397c2.049-15.335,15.155-27.169,31.051-27.169
	c11.733,0,21.936,6.454,27.308,15.998c1.229,2.184,3.763,3.281,6.149,2.515c10.978-3.526,22.744,0.815,29.007,10.392
	L363.066,194.588c-4.346-8.693-16.751-8.693-21.098,0L202.02,474.485h104.629C299.016,474.485,294.871,469.259,294.979,461.601z"/>
<path style={{ fill: "#995F30" }} d="M363.066,194.588c-4.346-8.693-16.751-8.693-21.098,0L202.02,474.485h21.652l139.67-279.34
	L363.066,194.588z"/>
<path style={{ fill: "#B78046" }} d="M155.257,474.485H19.025l63.342-126.683l0,0c1.967-3.934,7.582-3.934,9.549,0L155.257,474.485z"/>
<path style={{ fill: "#A86D38" }} d="M91.915,347.801c-1.967-3.934-7.582-3.934-9.549,0L19.025,474.485h20.806l57.713-115.426
	L91.915,347.801z"/>
<path style={{ fill: "#CC9562" }} d="M303.68,297.502c3.977,3.678,10.235,3.231,13.647-0.977l8.717-10.752
	c3.328-4.105,9.39-4.649,13.395-1.202l16.26,13.991c3.747,3.224,9.355,2.984,12.813-0.549l30.623-31.289l-36.069-72.137
	c-4.347-8.693-16.751-8.693-21.098,0l-47.293,94.585L303.68,297.502z"/>
<path style={{ fill: "#B78046" }} d="M363.066,194.588c-0.324-0.647-0.704-1.231-1.11-1.782c-5.051-6.847-15.964-6.264-19.987,1.782
	l-40.22,80.44l-7.073,14.146l9.004,8.329c2.049,1.896,4.703,2.687,7.263,2.441l52.4-104.798L363.066,194.588z"/>
<path style={{ fill: "#CC9562" }} d="M50.447,411.64l1.827,1.69c3.052,2.823,7.855,2.479,10.474-0.75l6.69-8.252
	c2.554-3.15,7.207-3.568,10.281-0.922l12.48,10.738c2.876,2.475,7.18,2.29,9.834-0.421l15.119-15.448l-25.237-50.473
	c-1.967-3.934-7.582-3.934-9.549,0L50.447,411.64z"/>
<path style={{ fill: "#B78046" }} d="M82.367,347.801L66.47,379.596L50.448,411.64l1.827,1.69c3.052,2.823,7.855,2.479,10.474-0.75
	l6.69-8.252c1.688-2.082,4.292-2.962,6.751-2.561l21.354-42.708l-5.628-11.257c-0.615-1.23-1.586-2.075-2.69-2.536
	c-0.663-0.277-1.373-0.415-2.084-0.415C85.246,344.851,83.35,345.834,82.367,347.801z"/>
<circle style={{ fill: "#FED159" }} cx="139.879" cy="111.33" r="37.929"/>
<path style={{ fill: "#FCB75B" }} d="M122.247,111.329c0-17.431,11.763-32.106,27.78-36.545c-3.232-0.896-6.633-1.384-10.149-1.384
	c-20.948,0-37.93,16.982-37.93,37.93s16.982,37.929,37.93,37.929c3.517,0,6.918-0.489,10.149-1.384
	C134.01,143.434,122.247,128.76,122.247,111.329z"/>
<g>
	<path style={{ fill: "#FED159" }} d="M139.878,59.607c-4.204,0-7.612-3.408-7.612-7.612V37.516c0-4.204,3.408-7.612,7.612-7.612
		c4.204,0,7.612,3.408,7.612,7.612v14.479C147.49,56.199,144.082,59.607,139.878,59.607z"/>
	<path style={{ fill: "#FED159" }} d="M139.878,192.752c-4.204,0-7.612-3.408-7.612-7.612v-14.479c0-4.204,3.408-7.612,7.612-7.612
		c4.204,0,7.612,3.408,7.612,7.612v14.479C147.49,189.344,144.082,192.752,139.878,192.752z"/>
	<path style={{ fill: "#FED159" }} d="M92.541,74.756L82.303,64.518c-2.973-2.972-2.973-7.792,0-10.765c2.972-2.972,7.792-2.973,10.765,0
		l10.239,10.238c2.973,2.972,2.973,7.792,0,10.765C100.334,77.728,95.514,77.729,92.541,74.756z"/>
	<path style={{ fill: "#FED159" }} d="M186.689,168.903l-10.238-10.239c-2.972-2.973-2.972-7.792,0-10.765
		c2.973-2.973,7.792-2.973,10.765,0l10.238,10.239c2.972,2.973,2.972,7.792,0,10.765
		C194.482,171.876,189.663,171.877,186.689,168.903z"/>
	<path style={{ fill: "#FED159" }} d="M80.546,118.941h-14.48c-4.204,0-7.612-3.408-7.612-7.612s3.408-7.612,7.612-7.612h14.479
		c4.204,0,7.612,3.408,7.612,7.612S84.75,118.941,80.546,118.941z"/>
	<path style={{ fill: "#FED159" }} d="M213.691,118.941h-14.479c-4.204,0-7.612-3.408-7.612-7.612s3.408-7.612,7.612-7.612h14.479
		c4.204,0,7.612,3.408,7.612,7.612S217.895,118.941,213.691,118.941z"/>
	<path style={{ fill: "#FED159" }} d="M82.303,168.903c-2.973-2.972-2.973-7.792,0-10.765L92.541,147.9c2.972-2.972,7.792-2.972,10.765,0
		s2.973,7.792,0,10.765l-10.239,10.239C90.094,171.876,85.276,171.876,82.303,168.903z"/>
	<path style={{ fill: "#FED159" }} d="M176.451,74.756c-2.972-2.972-2.972-7.792,0-10.765l10.238-10.238c2.973-2.972,7.792-2.973,10.765,0
		c2.972,2.973,2.972,7.792,0,10.765l-10.238,10.238C184.244,77.728,179.424,77.729,176.451,74.756z"/>
</g>
<path style={{ fill: "#99CC56" }} d="M495.541,441.16c-1.698-0.021-3.336,0.217-4.88,0.673c-2.027,0.599-4.223-0.437-5.032-2.39
	c-4.949-11.947-18.337-18.717-31.233-14.575c-2.386,0.767-4.92-0.331-6.149-2.515c-5.372-9.544-15.575-15.998-27.308-15.998
	c-15.896,0-29.002,11.834-31.051,27.169c-0.223,1.668-1.849,2.726-3.5,2.397c-5.017-0.998-10.396,0.024-14.846,3.135
	c-1.022,0.714-2.299,0.895-3.485,0.512c-9.047-2.924-12.611,2.838-15.295-1.876c-10.137-17.799-36.996-13.299-40.827,6.738
	c-0.338,1.771-1.953,2.946-3.744,2.739c-8.105-0.937-15.133,5.561-15.243,13.416c-0.108,7.657,6.068,13.899,13.701,13.899
	c18.251,0,165.799,0,188.688,0c9.43,0,17.031-7.834,16.649-17.348C511.636,448.393,504.292,441.267,495.541,441.16z"/>
<g>
	<path style={{ fill: "#8DBC42" }} d="M313.247,460.587c0.11-7.856,7.14-14.354,15.243-13.416c1.791,0.207,3.405-0.968,3.744-2.739
		c1.286-6.728,5.63-12.355,11.521-15.417c-13.049-6.806-29.051,0.932-31.82,15.417c-0.338,1.771-1.953,2.946-3.744,2.739
		c-8.105-0.938-15.133,5.561-15.243,13.416c-0.108,7.657,6.068,13.899,13.7,13.899c3.214,0,10.451,0,20.299,0
		C319.315,474.485,313.14,468.244,313.247,460.587z"/>
	<path style={{ fill: "#8DBC42" }} d="M477.231,474.485c0,4.204-3.408,7.612-7.612,7.612H7.612c-4.204,0-7.612-3.408-7.612-7.612
		c0-4.204,3.408-7.612,7.612-7.612h462.007C473.823,466.873,477.231,470.281,477.231,474.485z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
  </svg>
);

Ceremony.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Ceremony;
