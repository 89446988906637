import React from 'react';

const Footer = () => (
  <footer className="bg-black small text-center text-white-50">
    <div className="container">
      Copyright &copy; Pat Emery 2021
    </div>
  </footer>
);

export default Footer;