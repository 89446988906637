/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const Dinner = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 512 550" width={width}>
<path style={{ fill: "#E6E5EA" }} d="M256.299,128.74c-78.167,0-141.534,63.366-141.534,141.534c0,78.166,63.366,141.534,141.534,141.534
	s141.534-63.366,141.534-141.534C397.832,192.107,334.466,128.74,256.299,128.74z M256.299,362.411
	c-50.886,0-92.138-41.252-92.138-92.138s41.252-92.138,92.138-92.138s92.138,41.252,92.138,92.138S307.185,362.411,256.299,362.411z
	"/>
<circle style={{ fill: "#B4C0C8" }} cx="256.298" cy="270.277" r="92.139"/>
<path style={{ fill: "#7CC1EE" }} d="M469.386,150.559c-3.394-27.645-15.567-53.471-34.728-73.685l-0.87-0.918
	c-7.994-8.433-22.115-1.777-20.699,9.755l27.185,221.4l47.617-5.847L469.386,150.559z"/>
<path style={{ fill: "#EECC77" }} d="M487.891,301.265L487.891,301.265l-47.617,5.847l0,0l13.929,113.44
	c1.615,13.149,13.583,22.5,26.732,20.886l0,0c13.149-1.615,22.5-13.583,20.885-26.732L487.891,301.265z"/>
<path style={{ fill: "#7CC1EE" }} d="M10.474,407.803c-2.141,13.845,7.596,26.717,21.501,28.424l0,0
	c13.905,1.707,26.467-8.427,27.739-22.378L80.538,185.51l-35.028-4.301L10.474,407.803z"/>
<g>
	<path style={{ fill: "#040507" }} d="M256.299,118.741c-83.556,0-151.534,67.977-151.534,151.533s67.978,151.534,151.534,151.534
		s151.534-67.978,151.534-151.534S339.855,118.741,256.299,118.741z M256.299,401.807c-72.528,0-131.534-59.006-131.534-131.534
		S183.771,138.74,256.299,138.74s131.534,59.005,131.534,131.533S328.827,401.807,256.299,401.807z"/>
	<path style={{ fill: "#040507" }} d="M332.432,217.409c-4.847,2.646-6.631,8.722-3.985,13.569c6.536,11.971,9.99,25.56,9.99,39.296
		c0,45.291-36.847,82.138-82.138,82.138s-82.138-36.848-82.138-82.138s36.847-82.138,82.138-82.138
		c4.346,0,8.708,0.342,12.963,1.017c5.461,0.867,10.578-2.857,11.442-8.312c0.864-5.455-2.857-10.578-8.312-11.442
		c-5.288-0.838-10.703-1.263-16.094-1.263c-56.318,0-102.138,45.819-102.138,102.138s45.819,102.138,102.138,102.138
		s102.139-45.82,102.139-102.138c0-17.075-4.3-33.978-12.436-48.88C343.354,216.547,337.28,214.763,332.432,217.409z"/>
	<path style={{ fill: "#040507" }} d="M303.882,197.611c0.25,0.601,0.56,1.181,0.92,1.721c0.37,0.55,0.78,1.06,1.24,1.52
		c0.47,0.46,0.98,0.88,1.53,1.25c0.54,0.36,1.12,0.67,1.72,0.92c0.61,0.25,1.23,0.44,1.87,0.57c0.65,0.13,1.31,0.189,1.96,0.189
		s1.31-0.06,1.95-0.189c0.64-0.129,1.27-0.32,1.87-0.57c0.61-0.25,1.19-0.56,1.73-0.92c0.55-0.37,1.06-0.79,1.52-1.25
		c0.46-0.46,0.88-0.97,1.24-1.52c0.36-0.54,0.67-1.12,0.92-1.721c0.25-0.609,0.44-1.239,0.57-1.869c0.13-0.65,0.2-1.301,0.2-1.961
		c0-2.63-1.07-5.21-2.93-7.069c-0.46-0.46-0.97-0.88-1.52-1.24c-0.54-0.36-1.12-0.67-1.73-0.92c-0.6-0.25-1.23-0.44-1.87-0.57
		c-1.29-0.26-2.62-0.26-3.91,0c-0.64,0.13-1.26,0.32-1.87,0.57c-0.6,0.25-1.18,0.56-1.72,0.92c-0.55,0.36-1.06,0.78-1.53,1.24
		c-0.46,0.46-0.87,0.97-1.24,1.52c-0.36,0.54-0.67,1.12-0.92,1.73c-0.25,0.6-0.44,1.229-0.57,1.87c-0.13,0.64-0.19,1.3-0.19,1.949
		c0,0.66,0.06,1.311,0.19,1.961C303.442,196.381,303.632,197.002,303.882,197.611z"/>
	<path style={{ fill: "#040507" }} d="M479.311,149.34c-2.257-18.384-8.03-35.879-17.16-51.996c-5.604-9.895-12.412-19.096-20.234-27.348
		l-0.873-0.921c-6.664-7.031-16.632-8.905-25.398-4.774c-8.764,4.13-13.664,13.013-12.483,22.629l41.113,334.841
		c0.132,1.075,0.313,2.132,0.541,3.17c3.416,15.563,17.316,26.68,33.169,26.678c1.375,0,2.768-0.083,4.166-0.255l0,0
		c11.044-1.356,20.211-7.897,25.358-16.882c0.542-0.946,1.039-1.918,1.489-2.915c0.45-0.997,0.853-2.017,1.207-3.059
		c1.591-4.686,2.18-9.79,1.538-15.021L479.311,149.34z M423.014,84.493c-0.049-0.401-0.18-1.47,1.159-2.101
		c0.367-0.173,0.689-0.24,0.969-0.24c0.741,0,1.186,0.47,1.386,0.682l0.873,0.921c6.708,7.076,12.544,14.964,17.348,23.446
		c7.827,13.818,12.777,28.817,14.712,44.578l17.286,140.78l-27.767,3.409L423.014,84.493z M491.028,422.743
		c-1.823,4.616-6.049,8.124-11.312,8.77l0,0c-7.659,0.946-14.649-4.523-15.589-12.179l-12.71-103.515l27.767-3.409l12.71,103.515
		C492.188,418.316,491.856,420.644,491.028,422.743z"/>
	<path style={{ fill: "#040507" }} d="M122.938,156.947l9.095-74.072c0.673-5.481-3.225-10.471-8.707-11.145
		c-5.484-0.676-10.471,3.226-11.144,8.707l-9.095,74.072c-1.418,11.544-10.747,20.253-22.292,20.957l-6.629-0.814l11.961-97.413
		c0.673-5.481-3.225-10.471-8.707-11.145c-5.483-0.679-10.471,3.226-11.144,8.707l-11.961,97.413l-6.635-0.814
		c-11.03-3.478-17.973-14.184-16.556-25.727l9.095-74.071c0.673-5.481-3.225-10.471-8.707-11.145
		c-5.481-0.68-10.471,3.226-11.144,8.707l-9.095,74.071c-2.275,18.533,7.184,35.909,23.14,44.243L0.393,406.415
		c-0.004,0.024-0.008,0.05-0.012,0.075C-1,415.849,1.397,425.148,7.13,432.673c5.733,7.525,14.062,12.305,23.444,13.457l0.182,0.022
		c1.449,0.178,2.895,0.266,4.33,0.266c7.909,0,15.512-2.674,21.774-7.726c7.399-5.969,11.949-14.47,12.812-23.937l20.108-220.477
		C107.279,190.053,120.663,175.481,122.938,156.947z M49.756,412.94c-0.368,4.029-2.304,7.646-5.453,10.187
		c-3.149,2.541-7.091,3.675-11.102,3.176l-0.182-0.022c-3.996-0.49-7.541-2.524-9.98-5.727c-2.434-3.194-3.455-7.139-2.877-11.111
		l33.741-217.128l7.891,0.969c0.004,0,0.007,0.001,0.011,0.002c0.003,0,0.006,0,0.009,0l7.885,0.968L49.756,412.94z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
  </svg>
);

Dinner.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Dinner;
