import React from 'react';
import {
    Wine, Ceremony, Dinner, Party,
  } from '../assets/svgs';

const Button = ({
    i, title, date, onClick, selected,
  }) => (
    <button
      style={{
        background: selected ? '#A9C3B5' : '#EFEFEF',
        padding: 6,
        border: 'none',
        width: '100%',
        height: '80px'
      }}
      onClick={() => onClick(i)}
    >
      <b className="text-black-80">{ title }</b>
    </button>
  );

  const Image = ({ image }) => {
    const height = typeof window !== 'undefined' && window.innerWidth < 400 ? 100 : 150;
    const width = typeof window !== 'undefined' && window.innerWidth < 400 ? 100 : 150;
    switch (image) {
      case 'wine':
        return <Wine height={height} width={width} />;
      case 'ceremony':
        return <Ceremony height={height} width={width} />;
      case 'dinner':
        return <Dinner height={height} width={width} />;
      case 'party':
        return <Party height={height} width={width} />;
      default:
        return <p>Hello</p>;
    }
  };

class Info extends React.Component {
    state = {
        animateInterval: "",
        currentSlide: 0,
        stopAnimate: false,
        slides: [
            {
                id: 0,
                title: 'Rehearsal Dinner',
                date: 'Friday, June 18th 6PM',
                location: 'Sweet Peas Cafe',
                image: 'wine',
                link: 'https://www.sweetpeascafemaine.com',
            },
            {
                id: 1,
                title: 'Ceremony',
                date: 'Saturday, June 19th 11AM',
                location: 'Bar Harbor Inn',
                image: 'ceremony',
                link: 'https://barharborinn.com/bar-harbor-wedding/ceremonies/',
            },
            {
                id: 2,
                title: 'Reception',
                date: 'Saturday, June 19th Noon',
                location: 'Reading Room',
                image: 'dinner',
                link: 'https://barharborinn.com/dining/reading-room/',
            },
            {
                id: 3,
                title: 'Possible After Party?',
                date: 'Saturday, June 19th 7:30PM',
                location: 'TBD',
                image: 'party',
                link: '',
            },
            ],
      }
  
      actions = {
          setCurrentSlide: (i) => {
            const { animateInterval } = this.state;
            this.setState({ currentSlide: i })
            clearInterval(animateInterval);
          },
          animateSlide: () => this.setState((prevState) => (prevState.currentSlide < 3 ? ({ currentSlide: prevState.currentSlide += 1 }) : ({ currentSlide: 0 }))),
          animate: () => {
            const animateInterval = setInterval(() => this.actions.animateSlide(), 5000);
            this.setState({ animateInterval });
          }
      }
  
      componentDidMount() {
        const { animate } = this.actions;
        setTimeout(() => animate(), 3000);
      }
    render() {
        const { slides, currentSlide } = this.state;
        const { setCurrentSlide } = this.actions;
        return (
            <div className="justify-content-end" style={{ display: 'flex', flexDirection: 'column'}}>
                <a href={slides[currentSlide].link }>
                  <div className="container">
                    <h2 style={{ fontWeight: 800, color: '#FFFFFF', textAlign: 'left' }}>Schedule</h2>
                    <br />
                    <div className="row align-items-center no-gutters mb-4 mb-lg-5">
                      <div className="col-xl-4 col-lg-5">
                        <Image image={ slides[currentSlide].image } />
                      </div>
                      <div className="col-xl-8 col-lg-7">
                        <div className="featured-text text-center text-lg-left">
                          <h3 style={{ color: '#FFF' }} >{ slides[currentSlide].location }</h3>
                          <b className="primary mb-0">{ slides[currentSlide].date }</b>
                        </div>
                      </div>
                  </div>
                </div>
              </a>
                <div className="row no-gutters">
                    { slides.map((slide, i) => (
                        <div key={slides[i].id} className="col">
                            <Button
                                i={i}
                                selected={currentSlide === slides[i].id}
                                title={slides[i].title}
                                date={slides[i].date}
                                onClick={setCurrentSlide}
                            />
                        </div>
                        )) }
                  </div>
            </div>
        )
    }
}

export default Info;